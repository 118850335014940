import React, { useContext } from "react";
import {
  StyledFeaturesScroller,
  StyledFeaturesScrollerItems,
  StyledFeaturesScrollerItem,
  StyledFeaturesScrollerItemLogo,
  StyledFeaturesScrollerItemText,
  StyledFeaturesScrollerItemCTA,
} from "./style.jsx";
import { StyledTitle } from "../globalstyle";
import Button from "../Button/Button";
import Icon, { icons } from "../Icon/Icon";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";
import { DataContext } from "../../contexts/DataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default ({
  items, text,  id ="gift", allImages, 
}) => {
  const { i18nLink } = useContext(DataContext);

  return (
    <StyledFeaturesScroller id= {id}>
      <StyledTitle big dangerouslySetInnerHTML={{ __html: text?.title }} />
      <StyledGridRow isFullMobile>
        <Scroller>
          <StyledFeaturesScrollerItems>
            {items.map((item, index) => {
              const imageData = allImages.find(e => e.path === item.image);

              return (
                <StyledFeaturesScrollerItem key={index}>
                  {imageData && (
                    <StyledFeaturesScrollerItemLogo>
                      <GatsbyImage image={getImage(imageData)} alt={imageData.alt} />
                    </StyledFeaturesScrollerItemLogo>)}
                  <StyledFeaturesScrollerItemText>
                    {item.text}
                  </StyledFeaturesScrollerItemText>
                  <StyledFeaturesScrollerItemCTA>
                    <Button as="div" to={item.cta.url ? i18nLink(`${item.cta.url}`) : null}>
                      {item.cta.label} <Icon icon={icons.arrowRight} />
                    </Button>
                  </StyledFeaturesScrollerItemCTA>
                </StyledFeaturesScrollerItem>
              );},
            )}
          </StyledFeaturesScrollerItems>
        </Scroller>
      </StyledGridRow>
    </StyledFeaturesScroller>
  );
};

/*
<StyledFeaturesScrollerItemLogo src={item.logo} loading="lazy"/>*/
