import styled from "styled-components";
import {
  BP,
  Rem,
  Shadow,
} from "../../commons/Theme";

export const StyledFeaturesScroller = styled.div``;

export const StyledFeaturesScrollerItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding: ${Rem(36)} 0 ${Rem(24)};

  @media (${BP.ipad}) {
    padding: ${Rem(80)} 0 ${Rem(24)};
  }
`;

export const StyledFeaturesScrollerItem = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  min-width: 80vw;
  margin-left: ${Rem(20)};
  padding: ${Rem(30)} ${Rem(20)} ${Rem(120)};
  ${Shadow};

  @media (${BP.ipad}) {
    min-width: 40%;
    display: flex;
  }
`;

export const StyledFeaturesScrollerItemLogo = styled.div`
  width: 100%;
  padding-right: ${Rem(10)};
  padding-bottom: ${Rem(10)};
  @media (${BP.ipad}) {
    width: 45%;
  }
`;

export const StyledFeaturesScrollerItemText = styled.div`
  font-size: ${Rem(14)};
  line-height: ${Rem(19)};
  font-weight: normal;
  width: 100%;
  @media (${BP.ipad}) {
    width: 55%;
  }
`;

export const StyledFeaturesScrollerItemCTA = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  @media (${BP.ipad}) {
    left: auto;
    right: 0;
  }
`;
